var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "\r\n<table class=\"table\">\r\n    <thead>\r\n    <tr>\r\n        <th>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":42}}}))
    + "\r\n        </th>\r\n        <th>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":39}}}))
    + "\r\n        </th>\r\n        <th>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"value",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":40}}}))
    + "\r\n        </th>\r\n        <th>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"action",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":41}}}))
    + "\r\n        </th>\r\n    </tr>\r\n    </thead>\r\n    <tbody></tbody>\r\n</table>\r\n";
},"useData":true});