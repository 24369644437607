var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<button class=\"dialog--close btn btn-primary\" data-action=\"print\"><i class=\"fa fa-print\"></i></button>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"img-gallery\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"product_images") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"img-container\">\r\n                    <div class=\"img\" style=\"background-image: url('"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\upx\\templates\\helpers\\server.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"big_url") : depth0),{"name":"upx$server","hash":{},"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":89}}}))
    + "');\"></div>\r\n                </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_price") : stack1)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_default_price") : stack1)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":134}}}))
    + "\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <s class=\"discount-original-price\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_price") : stack1)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_default_price") : stack1)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":39,"column":59},"end":{"line":39,"column":169}}}))
    + "</s>\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_price") : stack1)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_price") : stack1)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":126}}}))
    + "\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        ( "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"purchase_price",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":44,"column":26},"end":{"line":44,"column":63}}}))
    + ": <span data-ui=\"purchase-price\"><i class=\"far fa-spinner fa-spin\"></i></span>)\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\markdown\\templates\\helpers\\render.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"summary") : stack1),{"name":"markdown$render","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":78}}}))
    + "\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                        -\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\markdown\\templates\\helpers\\render.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"body") : stack1),{"name":"markdown$render","hash":{},"data":data,"loc":{"start":{"line":68,"column":24},"end":{"line":68,"column":75}}}))
    + "\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product_attributes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":79,"column":54},"end":{"line":79,"column":95}}}))
    + "</label>\r\n                <div class=\"form-control-static col-md-9\">\r\n                    <table>\r\n                        <thead>\r\n                        <tr>\r\n                            <th></th>\r\n                            <th></th>\r\n                        </tr>\r\n                        </thead>\r\n                        <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"content_vars") : stack1),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":28},"end":{"line":94,"column":37}}})) != null ? stack1 : "")
    + "                        </tbody>\r\n                    </table>\r\n\r\n                </div>\r\n            </div>\r\n        </div>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <tr>\r\n                                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":91,"column":40},"end":{"line":91,"column":49}}}) : helper)))
    + "</td>\r\n                                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":92,"column":40},"end":{"line":92,"column":49}}}) : helper)))
    + "</td>\r\n                                </tr>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group\">\r\n                <div class=\"row\">\r\n                    <label class=\"control-label col-sm-3\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"categories",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":106,"column":58},"end":{"line":106,"column":91}}}))
    + "</label>\r\n                    <div class=\"form-control-static col-md-9\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"categories") : stack1),{"name":"each","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":24},"end":{"line":110,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n            </div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"grid-item\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":109,"column":51},"end":{"line":109,"column":60}}}) : helper)))
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2 data-ui=\"title text-ellipsis\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h2>\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"has_cups_printer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":137}}})) != null ? stack1 : "")
    + "\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"product_images") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "    <form onsubmit=\"return false;\" class=\"form-horizontal form-bordered\" style=\"margin: 0;\">\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"product_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":54},"end":{"line":20,"column":89}}}))
    + "</label>\r\n                <p class=\"form-control-static col-md-9 text-ellipsis\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"product_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":54},"end":{"line":27,"column":91}}}))
    + "</label>\r\n                <p class=\"form-control-static col-md-9 text-ellipsis\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"slug") : stack1), depth0))
    + "</p>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"price",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":54},"end":{"line":34,"column":82}}}))
    + "</label>\r\n                <div class=\"form-control-static col-md-9\">\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_price") : stack1)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),"==",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"product_default_price") : stack1)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":41,"column":39}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showPurchasePrice") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":45,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"short_product_description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":52,"column":54},"end":{"line":52,"column":102}}}))
    + "</label>\r\n                <div class=\"form-control-static col-md-9\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"summary") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":54,"column":20},"end":{"line":58,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"long_product_description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":65,"column":54},"end":{"line":65,"column":101}}}))
    + "</label>\r\n                <div class=\"form-control-static col-md-9\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"body") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":67,"column":20},"end":{"line":71,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"content_vars") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":8},"end":{"line":101,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"product_model") : depth0)) != null ? lookupProperty(stack1,"flat_product") : stack1)) != null ? lookupProperty(stack1,"categories") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":8},"end":{"line":114,"column":15}}})) != null ? stack1 : "")
    + "\r\n    </form>\r\n</div>\r\n\r\n<div data-region=\"popup\"></div>\r\n";
},"useData":true});