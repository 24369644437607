var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <h4> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pin_terminal",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":17},"end":{"line":12,"column":52}}}))
    + " </h4>\r\n            <div data-region=\"pin-terminal\"></div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"pos-setup__setting\">\r\n    <div class=\"loader-wrapper\" data-name=\"pos-setup\"><div class=\"loader\"></div></div>\r\n    <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"setup_pos",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":40}}}))
    + "</h2>\r\n    <h4>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"to_ensure_that_you_can_get_to_work_quickly_it_is_good_that_you_check_your_setting_and_adjust_it_if_you_want_something_else_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":157}}}))
    + "\r\n    </h4>\r\n    <div>\r\n        <h4> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"sales_channel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":13},"end":{"line":8,"column":49}}}))
    + " </h4>\r\n        <div data-region=\"sales-point\"></div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pinEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <h4> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"receipt_printer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":13},"end":{"line":16,"column":51}}}))
    + " </h4>\r\n        <div data-region=\"receipt-printer\"></div>\r\n    </div>\r\n\r\n    <button class=\"btn btn-info btn-block\" disabled data-ui=\"confirm\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"continue",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":70},"end":{"line":20,"column":101}}}))
    + "</button>\r\n</div>\r\n<div class=\"pos-setup__image\">\r\n    <img src=\"images/pos-setup.jpeg\" alt=\"setup\" class=\"img-responsive\">\r\n</div>\r\n";
},"useData":true});