var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2 data-ui=\"title\"><strong data-ui=\"customer_name\"></strong> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"has_multiple_customer_cards",((stack1 = (depth0 != null ? lookupProperty(depth0,"relation") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":66},"end":{"line":2,"column":130}}}))
    + "</h2>\r\n\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\" data-region=\"list\"></div>";
},"useData":true});