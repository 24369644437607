var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"alert alert-info\">\r\n                "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"this_order_is_an_icl_order_which_can_not_be_refunded",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":91}}}))
    + "\r\n            </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h2>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"relation_data") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " <button class=\"btn btn-primary btn-regular\" data-action=\"view-customer\">\r\n                "
    + alias1(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"view_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":52}}}))
    + "\r\n            </button></h2>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "            <h3>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"invoices",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":34,"column":47}}}))
    + "</h3>\r\n            <div data-region=\"invoice-list\"></div>\r\n            <button class=\"btn btn-primary btn-regular create-invoice-btn\" data-action=\"create-invoice\" style=\"display: none\">\r\n                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"create_invoice_for_full_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":37,"column":68}}}))
    + "\r\n            </button>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-lg btn-default\" data-action=\"print-receipt\">\r\n                <li class=\"fa fa-print\"></li> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":51,"column":46},"end":{"line":51,"column":82}}}))
    + "\r\n            </button>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"header\">\r\n    <div class=\"dialog--header\">\r\n        <h2 data-ui=\"title\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":56}}}))
    + " #"
    + alias2(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":3,"column":58},"end":{"line":3,"column":68}}}) : helper)))
    + "</h2>\r\n        <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n    </div>\r\n</div>\r\n\r\n<div class=\"dialog--content\">\r\n    <div class=\"product-list product-list-return\">\r\n        <div class=\"loader-wrapper\" data-name=\"list-payments-products\"><div class=\"loader\"></div></div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAnyIclOrderItem") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_anonymous") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "\r\n        <h3>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"payments",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":43}}}))
    + "\r\n            <span data-ui=\"sync-status\" class=\"sync-status\"></span>\r\n            <button data-ui=\"sync-button\" class=\"btn btn-warning\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"retry",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":66},"end":{"line":26,"column":94}}}))
    + "</button>\r\n        </h3>\r\n\r\n        <div class=\"payment-details\">\r\n            <div data-region=\"payment-list\"></div>\r\n        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvoiceFeatureAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <h3>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"return_products",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":41,"column":50}}}))
    + "</h3>\r\n        <div data-region=\"product-list\"></div>\r\n    </div>\r\n    <div class=\"keypad\" data-region=\"keypad\"></div>\r\n\r\n    <div class=\"loader-wrapper\" data-name=\"loader-print\"><div class=\"loader\"></div></div>\r\n\r\n    <div class=\"print-receipt full-child-height\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_receipt_printer") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"add-to-checkout full-child-height\">\r\n        <button class=\"btn btn-lg btn-success\" data-action=\"checkout\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableRefund") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":70},"end":{"line":56,"column":106}}})) != null ? stack1 : "")
    + ">\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_to_checkout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":57,"column":50}}}))
    + "\r\n        </button>\r\n    </div>\r\n</div>\r\n\r\n\r\n<div data-region=\"popup\" class=\"hidden\"></div>\r\n";
},"useData":true});