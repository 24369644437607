var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"header\">\r\n    <div class=\"dialog--header\">\r\n        <h2 data-ui=\"title\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"saved_orders",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":63}}}))
    + "</h2>\r\n        <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n    </div>\r\n</div>\r\n\r\n<div class=\"dialog--content\">\r\n\r\n    <table class=\"table table-vcenter table-striped\" data-region=\"list\">\r\n        <thead>\r\n            <tr>\r\n                <th class=\"datetime\">\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"date_and_time",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":56}}}))
    + "\r\n                </th>\r\n                <th class=\"text-right\">\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"qty_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":50}}}))
    + "\r\n                </th>\r\n                <th class=\"text-right\" >\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"total_amount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":55}}}))
    + "\r\n                </th>\r\n                <th class=\"actions\">&nbsp;</th>\r\n                <th class=\"actions\">&nbsp;</th>\r\n            </tr>\r\n        </thead>\r\n    </table>\r\n\r\n</div>\r\n\r\n<div class=\"dialog--footer\">\r\n    <button class=\"btn btn-warning btn-regular\" data-action=\"clear-all\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"clear_all_orders",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":72},"end":{"line":31,"column":111}}}))
    + "</button>\r\n    <button class=\"btn btn-success btn-regular\" data-action=\"new-order\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"start_new_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":72},"end":{"line":32,"column":110}}}))
    + "</button>\r\n</div>\r\n\r\n<div data-region=\"popupInPopup\" class=\"hidden\"></div>";
},"useData":true});