var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\r\n    <h2 data-ui=\"title\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"scan_new_customer_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":69}}}))
    + "</h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n    <div class=\"input\">\r\n        <label for=\"code\" class=\"control-label\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"scan_customer_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":89}}}))
    + "</label>\r\n        <input type=\"text\" data-ui=\"code\" class=\"form-control code\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"scan_the_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":81},"end":{"line":8,"column":117}}}))
    + "\" disabled>\r\n    </div>\r\n    <button class=\"btn btn-success accept\" data-ui=\"accept\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"accept",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":37}}}))
    + "\r\n    </button>\r\n    <div data-region=\"keypad\" class=\"keypad\"></div>\r\n</div>";
},"useData":true});