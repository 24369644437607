var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + alias4(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":26}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"totalItems") || (depth0 != null ? lookupProperty(depth0,"totalItems") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalItems","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":42}}}) : helper)))
    + ")\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + alias4(((helper = (helper = lookupProperty(helpers,"distance") || (depth0 != null ? lookupProperty(depth0,"distance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"distance","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":24}}}) : helper)))
    + "km - "
    + alias4(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":8,"column":29},"end":{"line":8,"column":43}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"totalItems") || (depth0 != null ? lookupProperty(depth0,"totalItems") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalItems","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":59}}}) : helper)))
    + ")\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isComplete") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":24,"column":23}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h2 class=\"confirmation\" >\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + "                </h2>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"it_will_be_picked_up_in_store_at_{time_confirmed}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":24},"end":{"line":19,"column":98}}}))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"it_should_be_delivered_by_{time_confirmed}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":21,"column":91}}}))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\r\n                        <td class=\"item-quantity\"><b>"
    + alias4(((helper = (helper = lookupProperty(helpers,"quantity") || (depth0 != null ? lookupProperty(depth0,"quantity") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quantity","hash":{},"data":data,"loc":{"start":{"line":30,"column":53},"end":{"line":30,"column":65}}}) : helper)))
    + "&nbsp;&times;</b></td>\r\n                        <td class=\"item-category\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":31,"column":50},"end":{"line":31,"column":62}}}) : helper)))
    + "</td>\r\n                        <td class=\"item-description\"><span class=\"item-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":32,"column":77},"end":{"line":32,"column":85}}}) : helper)))
    + "</span>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sub_items") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":36,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"instructions") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":39,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\r\n                    </tr>\r\n\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":34,"column":58}}})) != null ? stack1 : "")
    + "\r\n                                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":35,"column":32},"end":{"line":35,"column":40}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":40},"end":{"line":35,"column":69}}})) != null ? stack1 : "")
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "<br/>";
},"15":function(container,depth0,helpers,partials,data) {
    return ",";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <br/><b>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"instructions") || (depth0 != null ? lookupProperty(depth0,"instructions") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"instructions","hash":{},"data":data,"loc":{"start":{"line":38,"column":40},"end":{"line":38,"column":56}}}) : helper)))
    + "</b>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-warning pull-right\"> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pickup",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":49,"column":66},"end":{"line":49,"column":95}}}))
    + "</span><br/>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-info pull-right\"> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":51,"column":63},"end":{"line":51,"column":94}}}))
    + "</span><br/>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr><td>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"delivery_distance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":58,"column":64}}}))
    + "</td><td class=\"text-info\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"distance") || (depth0 != null ? lookupProperty(depth0,"distance") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"distance","hash":{},"data":data,"loc":{"start":{"line":58,"column":91},"end":{"line":58,"column":103}}}) : helper)))
    + "km</td></tr>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addressLine1") || (depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addressLine1","hash":{},"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":66,"column":28}}}) : helper)))
    + "<br/>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addressLine2") || (depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addressLine2","hash":{},"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":69,"column":28}}}) : helper)))
    + "<br/>\r\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h4> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"customer_note",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":74,"column":21},"end":{"line":74,"column":57}}}))
    + "</h4>\r\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"notes") || (depth0 != null ? lookupProperty(depth0,"notes") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"notes","hash":{},"data":data,"loc":{"start":{"line":75,"column":16},"end":{"line":75,"column":25}}}) : helper)))
    + "\r\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "                        <td class=\"text-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":84,"column":49},"end":{"line":84,"column":76}}}))
    + "</td>\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "                        <td class=\"text-danger\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"to_be_paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":86,"column":48},"end":{"line":86,"column":81}}}))
    + "</td>\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"row\">\r\n        <label for=\"time\" class=\"time control-label vertical-align col-md-4\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"confirmed_time",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":106,"column":77},"end":{"line":106,"column":114}}}))
    + "</label>\r\n        <div class=\"col-md-4\">\r\n            <input type=\"text\" class=\"ignore-keyboard form-control\" id=\"time\" name=\"time\">\r\n            <div class=\"help-block\"></div>\r\n        </div>\r\n    </div>\r\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"button-container button-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"btnCount") || (depth0 != null ? lookupProperty(depth0,"btnCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"btnCount","hash":{},"data":data,"loc":{"start":{"line":114,"column":40},"end":{"line":114,"column":52}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasConfirmation") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":8},"end":{"line":117,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasKitchenOnConfirm") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":8},"end":{"line":120,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasKitchen") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":8},"end":{"line":123,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFinish") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":8},"end":{"line":135,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPayBtn") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":8},"end":{"line":138,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCancel") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":8},"end":{"line":141,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "            <button data-action=\"confirm\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"confirm_time",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":116,"column":73},"end":{"line":116,"column":108}}}))
    + "</button>\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "            <button data-action=\"kitchen-with-confirm\" class=\"btn btn-lg btn-default\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"in_kitchen_print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":119,"column":86},"end":{"line":119,"column":125}}}))
    + "</button>\r\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "            <button data-action=\"kitchen\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"in_kitchen_print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":122,"column":73},"end":{"line":122,"column":112}}}))
    + "</button>\r\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-action=\"reprint\" class=\"btn btn-lg btn-default\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"reprint_kitchen_delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":125,"column":73},"end":{"line":125,"column":120}}}))
    + "</button>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":126,"column":12},"end":{"line":134,"column":19}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPaid") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":127,"column":16},"end":{"line":131,"column":23}}})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    return "                    <button data-action=\"mark-picked-up\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"mark_as_picked_up",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":128,"column":88},"end":{"line":128,"column":128}}}))
    + "</button>\r\n";
},"48":function(container,depth0,helpers,partials,data) {
    return "                    <button data-action=\"pay-order\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pay_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":130,"column":83},"end":{"line":130,"column":115}}}))
    + "</button>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "                <button data-action=\"out-for-delivery\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"delivery_notify_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":133,"column":86},"end":{"line":133,"column":133}}}))
    + "</button>\r\n";
},"52":function(container,depth0,helpers,partials,data) {
    return "            <button data-action=\"pay-order\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pay_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":137,"column":75},"end":{"line":137,"column":107}}}))
    + "</button>\r\n";
},"54":function(container,depth0,helpers,partials,data) {
    return "            <button data-action=\"cancel\" class=\"btn btn-lg btn-danger\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"cancel_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":140,"column":71},"end":{"line":140,"column":106}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"order_{number}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":48}}}))
    + "\r\n        -\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "    </h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n    <div class=\"row\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasConfirmation") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"col-md-8\">\r\n            <table class=\"table table-condensed item-table\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":43,"column":25}}})) != null ? stack1 : "")
    + "            </table>\r\n        </div>\r\n        <div class=\"col-md-4 details\">\r\n            <h4> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"order_details",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":47,"column":17},"end":{"line":47,"column":53}}}))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":52,"column":23}}})) != null ? stack1 : "")
    + "            </h4>\r\n\r\n            <table class=\"property-table\">\r\n                <tr><td>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"amount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":56,"column":53}}}))
    + "</td><td class=\"text-info\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":56,"column":80},"end":{"line":56,"column":94}}}) : helper)))
    + "</td></tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":59,"column":27}}})) != null ? stack1 : "")
    + "            </table>\r\n\r\n            <h4> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"customer_info",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":62,"column":17},"end":{"line":62,"column":53}}}))
    + "</h4>\r\n            <b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"customerName") || (depth0 != null ? lookupProperty(depth0,"customerName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"customerName","hash":{},"data":data,"loc":{"start":{"line":63,"column":15},"end":{"line":63,"column":31}}}) : helper)))
    + "</b><br/>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":0},"end":{"line":67,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":0},"end":{"line":70,"column":7}}})) != null ? stack1 : "")
    + "            "
    + alias2(((helper = (helper = lookupProperty(helpers,"phoneNumber") || (depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"phoneNumber","hash":{},"data":data,"loc":{"start":{"line":71,"column":12},"end":{"line":71,"column":27}}}) : helper)))
    + "\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notes") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":76,"column":19}}})) != null ? stack1 : "")
    + "\r\n            <h4> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"payment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":78,"column":17},"end":{"line":78,"column":47}}}))
    + "</h4>\r\n\r\n            <table class=\"property-table\">\r\n                <tr>\r\n                    <td>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"payment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":82,"column":54}}}))
    + "</td>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPaid") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":83,"column":20},"end":{"line":87,"column":27}}})) != null ? stack1 : "")
    + "                </tr>\r\n                <tr>\r\n                    <td>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"payment_method",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":90,"column":24},"end":{"line":90,"column":61}}}))
    + "</td>\r\n                    <td class=\"text-info\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"paymentMethodName") || (depth0 != null ? lookupProperty(depth0,"paymentMethodName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"paymentMethodName","hash":{},"data":data,"loc":{"start":{"line":91,"column":42},"end":{"line":91,"column":63}}}) : helper)))
    + "</td>\r\n                </tr>\r\n                <tr>\r\n                    <td colspan=\"2\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"paymentMethodDetails") || (depth0 != null ? lookupProperty(depth0,"paymentMethodDetails") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"paymentMethodDetails","hash":{},"data":data,"loc":{"start":{"line":94,"column":36},"end":{"line":94,"column":60}}}) : helper)))
    + "</td>\r\n                </tr>\r\n            </table>\r\n\r\n        </div>\r\n\r\n    </div>\r\n</div>\r\n<div class=\"dialog--footer\">\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasConfirmation") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":4},"end":{"line":112,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"btnCount") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":0},"end":{"line":143,"column":7}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true});