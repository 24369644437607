var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"btn btn-regular btn-default\" data-action=\"customer\">\r\n                            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"view_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":15,"column":64}}}))
    + "\r\n                        </button>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"label label-success\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"yes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":58},"end":{"line":34,"column":84}}}))
    + "</span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"btn btn-regular btn-danger\" data-action=\"pay\">\r\n                            <i class=\"fas fa-euro-sign\"></i>\r\n                            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":28},"end":{"line":38,"column":54}}}))
    + " ("
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"value_outstanding_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":38,"column":56},"end":{"line":38,"column":117}}}))
    + ")\r\n                        </button>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"payments") || (depth0 != null ? lookupProperty(depth0,"payments") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"payments","hash":{},"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":42,"column":36}}}) : helper)))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"shipping_method",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":50,"column":54},"end":{"line":50,"column":92}}}))
    + "</label>\r\n                <div class=\"form-control-static col-md-9\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":20},"end":{"line":54,"column":27}}})) != null ? stack1 : "")
    + "                    "
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\r\n                    <span class=\"label label-"
    + alias2(((helper = (helper = lookupProperty(helpers,"shipment_label") || (depth0 != null ? lookupProperty(depth0,"shipment_label") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"shipment_label","hash":{},"data":data,"loc":{"start":{"line":56,"column":45},"end":{"line":56,"column":63}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"shipment_status") || (depth0 != null ? lookupProperty(depth0,"shipment_status") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"shipment_status","hash":{},"data":data,"loc":{"start":{"line":56,"column":65},"end":{"line":56,"column":84}}}) : helper)))
    + "</span>\r\n                </div>\r\n            </div>\r\n        </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + "\"></i>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"shipment collect\">\r\n    <h3>\r\n        <div class=\"pull-right\">\r\n            <button data-action=\"collect\" class=\"btn btn-regular btn-success\">\r\n                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"collect",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":71,"column":46}}}))
    + "\r\n            </button>\r\n        </div>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"to_be_processed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":74,"column":8},"end":{"line":74,"column":46}}}))
    + "\r\n    </h3>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"products") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":4},"end":{"line":92,"column":13}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"shipment-item designer-box rounded\">\r\n            <div class=\"img-container\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":79,"column":16},"end":{"line":83,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n            <div class=\"product-name\">\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"unfulfilled_quantity") : depth0),"!=",1,{"name":"common$compare","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":88,"column":31}}})) != null ? stack1 : "")
    + "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":89,"column":12},"end":{"line":89,"column":20}}}) : helper)))
    + "\r\n            </div>\r\n        </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"img\" style=\"background-image: url('"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\upx\\templates\\helpers\\server.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"imageUrl") : depth0),{"name":"upx$server","hash":{},"data":data,"loc":{"start":{"line":80,"column":63},"end":{"line":80,"column":86}}}))
    + "');\"></div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"img\" style=\"background-image: url('/images/no-product-image.png');\"></div>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <b>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"unfulfilled_quantity") || (depth0 != null ? lookupProperty(depth0,"unfulfilled_quantity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"unfulfilled_quantity","hash":{},"data":data,"loc":{"start":{"line":87,"column":19},"end":{"line":87,"column":43}}}) : helper)))
    + "&times;</b>\r\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"shipment\">\r\n    <h3 >\r\n        <div class=\"pull-right\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDeliverInStore") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":12},"end":{"line":105,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasReceiptPrinter") : depths[1]),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":12},"end":{"line":112,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n        <i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":114,"column":18},"end":{"line":114,"column":26}}}) : helper)))
    + "\"></i> "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":114,"column":33},"end":{"line":114,"column":41}}}) : helper)))
    + " #"
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"number") : depths[1]), depth0))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":114,"column":57},"end":{"line":114,"column":63}}}) : helper)))
    + " <br/><span class=\"label "
    + alias4(((helper = (helper = lookupProperty(helpers,"status_label") || (depth0 != null ? lookupProperty(depth0,"status_label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_label","hash":{},"data":data,"loc":{"start":{"line":114,"column":88},"end":{"line":114,"column":104}}}) : helper)))
    + "\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"status_name") || (depth0 != null ? lookupProperty(depth0,"status_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_name","hash":{},"data":data,"loc":{"start":{"line":114,"column":107},"end":{"line":114,"column":122}}}) : helper)))
    + "</span>\r\n    </h3>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"shipment_items") : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":4},"end":{"line":133,"column":13}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button data-action=\"deliver\" class=\"btn btn-regular btn-success\" data-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":101,"column":91},"end":{"line":101,"column":97}}}) : helper)))
    + "\">\r\n                    <i class=\"fas fa-hand-holding-box\"></i>\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pickup_at_the_store",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":103,"column":20},"end":{"line":103,"column":62}}}))
    + "\r\n                </button>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button data-action=\"print\" class=\"btn btn-regular btn-info\" data-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":108,"column":86},"end":{"line":108,"column":92}}}) : helper)))
    + "\">\r\n                    <i class=\"far fa-print\"></i>\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"print_contents",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":110,"column":20},"end":{"line":110,"column":57}}}))
    + "\r\n                </button>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"shipment-item designer-box rounded\">\r\n        <div class=\"img-container\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"main_image_url") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":120,"column":12},"end":{"line":124,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n        <div class=\"product-name\">\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"quantity") : depth0),"!=",1,{"name":"common$compare","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":12},"end":{"line":129,"column":31}}})) != null ? stack1 : "")
    + "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":130,"column":12},"end":{"line":130,"column":20}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"img\" style=\"background-image: url('"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\upx\\templates\\helpers\\server.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"main_image_url") : depth0),{"name":"upx$server","hash":{},"data":data,"loc":{"start":{"line":121,"column":63},"end":{"line":121,"column":92}}}))
    + "');\"></div>\r\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"img\" style=\"background-image: url('/images/no-product-image.png');\"></div>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <b>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"quantity") || (depth0 != null ? lookupProperty(depth0,"quantity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"quantity","hash":{},"data":data,"loc":{"start":{"line":128,"column":19},"end":{"line":128,"column":31}}}) : helper)))
    + "&times;</b>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2 data-ui=\"title text-ellipsis\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping_address") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + ": #"
    + alias2(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":2,"column":66},"end":{"line":2,"column":76}}}) : helper)))
    + "</h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n\r\n<div class=\"dialog--content\">\r\n    <form onsubmit=\"return false;\" class=\"form-horizontal form-bordered\" style=\"margin: 0;\">\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":54},"end":{"line":11,"column":81}}}))
    + "</label>\r\n                <p class=\"form-control-static col-sm-9 text-ellipsis\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping_address") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_anonymous") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":17,"column":31}}})) != null ? stack1 : "")
    + "                </p>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":54},"end":{"line":24,"column":81}}}))
    + "</label>\r\n                <p class=\"form-control-static col-sm-9 text-ellipsis\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias3,(depth0 != null ? lookupProperty(depth0,"date_purchased") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":25,"column":70},"end":{"line":25,"column":110}}}))
    + "</p>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"form-group\">\r\n            <div class=\"row\">\r\n                <label class=\"control-label col-sm-3\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias3,"paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":54},"end":{"line":31,"column":81}}}))
    + "</label>\r\n                <div class=\"form-control-static col-md-9\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_paid") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":40,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"payments") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":43,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n        </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":60,"column":15}}})) != null ? stack1 : "")
    + "\r\n    </form>\r\n\r\n    <br>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"products") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":0},"end":{"line":94,"column":7}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"shipments") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":0},"end":{"line":135,"column":9}}})) != null ? stack1 : "")
    + "\r\n</div>\r\n";
},"useData":true,"useDepths":true});