var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\r\n    <h2 data-ui=\"title\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"choose_an_add_on",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":63}}}))
    + "</h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\" data-region=\"region\"></div>\r\n<div class=\"dialog--footer\">\r\n    <button class=\"btn btn-default\" data-action=\"close\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"close",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":56},"end":{"line":7,"column":84}}}))
    + "</button>\r\n    <button class=\"btn btn-success\" data-action=\"confirm\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"apply",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":86}}}))
    + "</button>\r\n</div>";
},"useData":true});